import React, { useState } from "react"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import useScript from "../service/useScript"
const HindujaIVFCentre = () => {

const metaTags = []
return (
<>
<Layout>
    <Meta
    files={{
    js: [],
    css: ["/assets/css/about-through-the-ages.css", "/assets/css/accessibility.css"],
    }}
    tags={metaTags}
    />
    <main className="innerpage">
        <section className="breadcrumbs">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <ul>
                            <li><a href="javascript:;">Home</a></li>
                            <li><span>Hinduja IVF Centre</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className="section_bg pt-first-section pb-section about-ages">
            <div className="container">
                <div className="section-head">
                    <h1>Hinduja IVF Centre</h1>
                    <p>Our “Centres of Excellence” are dedicated to focussing on issues that affect millions. Health concerns that are oft overlooked till they no longer remain concerns. Through this focussed initiative of ours, <strong>we bring the best of international practices home</strong>. Redefining <strong>delivery of healthcare, as it has always been to what it should be</strong>. The focus is on achieving health, not curing illness.</p>
                </div>
            </div>
            <div className="container">
                <div className="tab-content mt-3">
                    <div className="tab-pane active" id="ivfcentre" role="tabpanel" aria-labelledby="ivfcentre">
                        <div className="card">
                            <div className="row">
                                <div className="col-md-6">
                                    <figure>
                                        <img className="ls-is-cached lazyloaded" src="assets/images/center-of-excellence/ce-ivf.jpg" alt="Hinduja IVF Centre" title="Hinduja IVF Centre" />
                                    </figure>
                                </div>
                                <div className="col-md-6 para_text">
                                    <div>
                                        <p>With the history of many firsts in delivering only the best to patients far and wide, comes another milestone.</p>
                                        <p><strong>Dr. Indira Hinduja</strong> now join hands with P. D. Hinduja Hospital. Hinduja IVF Centre is the fruition of our efforts with the aim providing the best of expertise and treatment options; very few can match up to.</p>
                                        <p><strong>Unrivalled expertise</strong>, standardised and <strong>transparent care delivery</strong> protocols, quality processes, hand-holding every step of the way from pre-conception to post delivery ensure <strong>you get your best chance at making your family complete</strong>.</p>
                                        <p className="mt-4"><a href="https://khar.hindujahospital.com/hindujaivfcentre/" className="btn-link" target="_blank">Visit site</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</Layout>
</>
)
}
export default HindujaIVFCentre